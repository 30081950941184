<template>
  <div>
    <v-container class="px-0">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" lg="12" md="12">
            <high-charts-drill-bar
              :id="1"
              :items="items"
              :name="'Proyecto'"
              :visibleSubTitle="true"
              :subtitle="'Resoluciones'"
              :number="true"
              :valueSuffix="'LOT'"
            />
          </v-col>
          <v-col cols="12" lg="12" md="12">
            <high-charts-drill-bar
              :id="4"
              :items="byYear"
              :name="'Año'"
              :visibleSubTitle="true"
              :subtitle="'Resoluciones por año'"
              :number="true"
              :valueSuffix="'LOT'"
            />
          </v-col>
          <v-col cols="12" lg="12" md="12">
            <high-charts-drill-bar
              :id="5"
              :items="paidTime"
              :name="'Duracion'"
              :visibleSubTitle="true"
              :subtitle="'Tiempo pagado'"
              :number="true"
              :valueSuffix="'LOT'"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import HighChartsDrillBar from "@/components/Utils/HighCharts/HighChartsDrillBar.vue";
export default {
  props: {
    items: {
      type: Array,
      default: [],
    },
    byYear: {
      type: Array,
      default: [],
    },
    paidTime: {
      type: Array,
      default: [],
    },
  },
  components: {
    HighChartsDrillBar,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
</style>
