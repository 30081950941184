<template>
  <div>
    <v-container class="px-0">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" lg="12" md="12">
            <high-charts-drill-bar
              :id="2"
              :items="unit"
              :name="'Promotor'"
              :visibleSubTitle="true"
              :subtitle="'Unidades'"
              :number="true"
              :valueSuffix="'LOT'"
            />
          </v-col>
          <v-col cols="12" lg="12" md="12">
            <high-charts-drill-bar
              :id="3"
              :items="capital"
              :name="'Promotor'"
              :visibleSubTitle="true"
              :subtitle="'Importe'"
              :number="true"
              :thousandsSep="true"
              :valueSuffix="'USD'"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import HighChartsDrillBar from "@/components/Utils/HighCharts/HighChartsDrillBar.vue";
export default {
  props: {
    capital: {
      type: Array,
      default: [],
    },
    unit:{
      type: Array,
      default: [],
    },
  },
  components: {
    HighChartsDrillBar,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
</style>
