<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <s-toolbar
            :color="'#244093'"
            dark
            label="Reporte Resoluciones"
            class="tran"
          ></s-toolbar>
          <v-row style="margin: auto">
            <v-card width="100%">
              <v-row style="margin: auto" class="ml-3">
                <v-col sm="6" cols="6" lg="4" md="4">
                  <s-select-definition
                    :def="1215"
                    v-model="objMounth"
                    return-object
                    label="Seleccione periodo"
                  ></s-select-definition>
                </v-col>
                <v-col cols="12" lg="4" md="4" class="mt-3">
                  <v-btn
                    rounded
                    :color="'info'"
                    small
                    @click="run()"
                    class="mr-2"
                    >Buscar</v-btn
                  >
                  <v-btn
                    rounded
                    :color="'error'"
                    small
                    @click="downloadReport()"
                    :disabled="disabledBtnDownload"
                    >Descargar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-tabs v-model="currentItem">
        <v-tab href="#ReportResolutions"> Reporte Resoluciones</v-tab>
        <v-tab href="#DasboardResolutions">Dashboard</v-tab>
        <v-tab href="#ReportPaidTime">Tiempo de Pago</v-tab>
        <v-tab href="#ReportBadSale">Mala Venta</v-tab>
        <v-tab href="#DasboardBadSale">Dashboard Mala Venta</v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentItem">
        <v-tab-item :value="'ReportResolutions'">
          <v-col cols="12">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Cuadrante, lote, Dni"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <gral-table
                  :headers="headers"
                  :items="items"
                  :propsdinamic="{ search, class: 'fixed-first-column' }"
                  :columnsNotNumber="[
                    'Codigo Venta',
                    'Documento',
                    'Año Cierre',
                    'Año Resolucion',
                  ]"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-tab-item>
        <v-tab-item :value="'ReportPaidTime'">
          <v-col cols="12">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="6">
                <v-text-field
                  v-model="searchPaidTime"
                  append-icon="mdi-magnify"
                  label="Cuadrante, lote, Dni"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <gral-table
                  :headers="headersPaidTime"
                  :items="itemsPaidTime"
                  :propsdinamic="{
                    search: searchPaidTime,
                    class: 'fixed-first-column',
                  }"
                  :columnsNotNumber="['AnioResolucion']"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-tab-item>
        <v-tab-item :value="'ReportBadSale'">
          <v-col cols="12">
            <v-row>
              <h2 class="subheading">Unidades</h2>
              <v-col cols="12">
                <gral-table
                  :headers="headersBadSale"
                  :items="itemsBadSaleUnit"
                  :propsdinamic="{
                    class: 'fixed-first-column',
                  }"
                />
              </v-col>
              <h2 class="subheading">Importe</h2>
              <v-col cols="12">
                <gral-table
                  :headers="headersBadSale"
                  :items="itemsBadSaleCapital"
                  :propsdinamic="{
                    class: 'fixed-first-column',
                  }"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-tab-item>
        <v-tab-item :value="'DasboardResolutions'">
          <clc-resolutions-dashboard
            :items="dataDasboard"
            :byYear="dataDasboardByYear"
            :paidTime="dataDasboardPaidTime"
          />
        </v-tab-item>
        <v-tab-item :value="'DasboardBadSale'">
          <clc-bad-sale-dashboard
            :capital="dataDasboardBadSaleCapital"
            :unit="dataDasboardBadSaleUnit"
          />
        </v-tab-item>
      </v-tabs-items>

      <v-dialog
        v-model="processing"
        :persistent="messageProcessing.length == 0"
        width="400"
      >
        <v-card color="primary" dark>
          <v-card-text v-if="messageProcessing.length == 0">
            Por favor espere
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
          <v-card-text v-else>
            <div class="text--white pt-4">
              Error al realizar busqueda <br />
              {{ messageProcessing }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import ClcResolutionsDashboard from "./ClcResolutionsDasboard.vue";
import ClcBadSaleDashboard from "./ClcResolutionsBadSaleDasboard.vue";
import GralTable from "../GralTable.vue";

export default {
  components: {
    ClcResolutionsDashboard,
    GralTable,
    ClcBadSaleDashboard,
  },
  data() {
    return {
      currentItem: "tab-Funciones",
      items: [],
      itemsPaidTime: [],
      itemsBadSaleUnit: [],
      itemsBadSaleCapital: [],
      headers: [],
      headersPaidTime: [],
      headersBadSale: [],
      report: {},
      processing: false,
      messageProcessing: "",
      objMonth: null,
      txtYear: this.$fun.getYear(),
      dataDasboard: [],
      dataDasboardByYear: [],
      dataDasboardBadSaleUnit: [],
      dataDasboardBadSaleCapital: [],
      dataDasboardPaidTime: [],
      dataDrillDownBadSaleUnit: [],
      dataDrillDownBadSaleCapital: [],
      search: "",
      searchPaidTime: "",
      objMounth: null,
      disabledBtnDownload: true,
    };
  },
  methods: {
    run() {
      this.runView();
      this.byYear();
      this.paidTime();
      this.badSale();
      this.disabledBtnDownload = false;
    },
    runView() {
      this.messageProcessing = "";
      this.report.QryParamsArray = "Period";
      this.report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      this.report.QryNameProcedure = "ClcReportResolutions_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(this.report, this.$fun.getUserID())
        .then(
          (resp) => {
            if (resp.status == 200) {
              this.processing = false;
              this.items = resp.data;

              for (var i = 0; i < Object.keys(this.items[0]).length; i++) {
                this.headers.splice(i, i, {
                  text: Object.keys(this.items[0])[i],
                  value: Object.keys(this.items[0])[i],
                  width: "120",
                  sortable: false,
                });
              }
              this.dataDasboard = Object.entries(
                _.groupBy(resp.data, "Proyecto Corto")
              ).map((items) => ({
                name: items[0],
                y: items[1].length,
              }));
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
    paidTime() {
      const report = {};
      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcReportResolutionsTime_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then(
          (resp) => {
            if (resp.status == 200) {
              this.processing = false;
              this.itemsPaidTime = resp.data.map((item) => ({
                ...item,
                Total: Object.entries(item)
                  .filter((item) => item[0] !== "AnioResolucion")
                  .reduce((suma, item) => suma + item[1], 0),
              }));

              this.headersPaidTime = Object.keys(this.itemsPaidTime[0]).map(
                (item) => ({
                  text: item,
                  value: item,
                  width: "120",
                  sortable: false,
                })
              );
              this.dataDasboardPaidTime = Object.entries(
                resp.data.find(
                  (item) => item.AnioResolucion === "Total General"
                )
              )
                .filter((items) => items[0] !== "AnioResolucion")
                .map((item) => ({ name: item[0], y: item[1] }));
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
    badSale() {
      const report = {};
      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcReportResolutionsBadSale_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then(
          (resp) => {
            if (resp.status == 200) {
              this.processing = false;
              this.itemsBadSaleCapital = resp.data.filter(
                (item) => item.Categoria === "Capital"
              );
              this.itemsBadSaleUnit = resp.data.filter(
                (item) => item.Categoria === "Unidades"
              );
              this.headersBadSale = Object.keys(resp.data[0])
                .filter((item) => item !== "Categoria")
                .map((item) => ({
                  text: item,
                  value: item,
                  width: item === "Promotor" ? "200" : "80",
                  sortable: false,
                }));
              this.dataDasboardBadSaleUnit = this.itemsBadSaleUnit
                .filter((item) => item.Promotor !== "Total general")
                .map((item) => ({ name: item.Promotor, y: item.Total }));

              this.dataDasboardBadSaleCapital = this.itemsBadSaleCapital
                .filter((item) => item.Promotor !== "Total general")
                .map((item) => ({ name: item.Promotor, y: item.Total }));
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
    byYear() {
      const report = {};
      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcReportResolutionsByYear_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then(
          (resp) => {
            if (resp.status == 200) {
              this.processing = false;

              this.dataDasboardByYear = resp.data.map((item) => ({
                name: item["Año resolucion"],
                y: item["CantidadLotes"],
              }));
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
    downloadReport() {
      const SheetNames = [
        "Resoluciones",
        "Timepo Antiguedad",
        "Mala Venta UNI",
        "Mala Venta Importe",
      ];
      const DataTables = [
        this.items,
        this.itemsPaidTime,
        this.itemsBadSaleUnit,
        this.itemsBadSaleCapital,
      ];
      const dataSend = { DataTables, SheetNames };

      this.processing = true;
      _sQryConfigurationService
        .downloadexcelrc(dataSend, this.$fun.getUserID())
        .then(
          (r) => {
            if (r.status == 200) {
              this.processing = false;

              this.$fun.downloadFile(
                r.data,
                this.$const.TypeFile.EXCEL,
                `Resoluciones ${this.objMounth.DedDescription}`
              );
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
.fixed-first-column thead tr,
.fixed-first-column tbody tr {
  position: relative;
}
.fixed-first-column thead th:first-child,
.fixed-first-column tbody td:first-child {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
}
</style>
